import { tw } from '@/utils/tw';
import CheckboxTheme from 'base/components/Checkbox/theme';

const Checkbox = tw.theme({
  extend: CheckboxTheme,
  slots: {
    label: ['text-body-md'],
    input: [
      'active:before:scale-0',
      'active:bg-gray-50',
      'after:-translate-x-1/2',
      'after:-translate-y-2/3',
      'after:absolute',
      'after:block',
      'after:border-b-2',
      'after:border-l-2',
      'after:border-logga-600',
      'after:h-1.25',
      'after:left-1/2',
      'after:origin-center',
      'after:scale-0',
      'after:top-1/2',
      'after:transition-all',
      'after:w-2.5',
      'appearance-none',
      'before:-translate-x-1/2',
      'before:-translate-y-1/2',
      'before:-z-2',
      'before:absolute',
      'before:bg-gray-100',
      'before:block',
      'before:h-[130%]',
      'before:left-1/2',
      'before:scale-0',
      'before:top-1/2',
      'before:transition-all',
      'before:w-[130%]',
      'bg-white',
      'checked:after:-rotate-45',
      'checked:after:scale-100',
      'focus:before:scale-100',
      'h-5',
      'hover:before:scale-100',
      'p-1.25',
      'relative',
      'shadow-gray-400',
      'shadow-inset-1',
      'transition-all',
      'w-5',
    ],
  },
  variants: {
    variant: {
      alternative: {
        label: ['hidden'],
        input: [
          'active:bg-gray-50',
          'active:outline-0',
          'active:shadow-md',
          'after:bg-logga-600',
          'before:bg-logga-600',
          'bg-white',
          'checked:active:bg-logga-700',
          'checked:after:bg-white',
          'checked:before:bg-white',
          'checked:bg-logga-600',
          'checked:shadow-inset-0',
          'focus:outline-4',
          'h-9',
          'hover:outline-4',
          'hover:shadow-inset-0',
          'outline-0',
          'outline-gray-100',
          'outline',
          'shadow-md',
          'w-9',
        ],
      },
    },
  },
});

export default Checkbox;
