import { CheckboxTheme } from '@/components/Checkbox/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: CheckboxTheme });

const Base = $({ as: 'div', slot: 'base' });
const Label = $({ as: 'label', slot: 'label' });
const Input = $({ as: 'input', slot: 'input' });
const Caption = $({ as: 'span', slot: 'caption' });

export const Checkbox = Object.assign(Base, { Caption, Input, Label });
